<template>
  <form @submit.prevent="onSubmit">
    <Stack tag="section" gap="md" direction="col">
      <Text tag="p" size="md" weight="semi" data-test-id="branch-tray-search-description"
        >Choose your nearest MKM branch for local stock and delivery information.</Text
      >
      <Stack tag="div" direction="col" gap="2xs" class="w-full">
        <Input
          data-test-id="searchLocationInput"
          :id="'branchSearch'"
          name="branch"
          :model-value="branch ?? ''"
          label="Enter postcode or town"
          placeholder="Enter postcode or town"
          @update:input-value="(val) => (branch = val)"
        />

        <button
          :class="{ invisible: geoLocationValue === 'denied' }"
          data-test-id="currentLocationButton"
          type="button"
          @click="getLocationFromBrowser"
          class="text-mkm-blue-default font-semi"
        >
          Or use your current location
        </button>
      </Stack>

      <Button
        data-test-id="searchLocationSubmitButton"
        type="submit"
        :disabled="Object.keys(errors).length > 0 || (!isSearching && !values.branch)"
        variant="secondary"
        size="lg"
        class="!w-full !justify-center"
        data-fs="searchBranchesButton"
      >
        search branches
        <Spinner v-show="isSearching" />
      </Button>
    </Stack>
  </form>
</template>

<script lang="ts" setup>
import { Steps } from "@/composables/useBranchTray/branchTrayTypes";
import { Loader } from "@googlemaps/js-api-loader";
import { useForm } from "vee-validate";
import * as yup from "yup";

const { GOOGLE_MAPS_API_KEY } = useRuntimeConfig().public;
const { updateSteps, nearestBranches } = useBranchTray();
const { fetchNearestBranch } = useBranches();
const { branchPostcode } = useUIState();

const { values, errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    branch: yup.string().required(),
  }),
});

const [branch] = defineField("branch");
branch.value = branchPostcode.value || "";

const UKDefaultCoords = { lat: 54.5, lng: -4 };
const currentLocation = ref(UKDefaultCoords);
const isSearching = ref(false);
const geoLocationValue = ref<"granted" | "prompt" | "denied">();

const updateGeoLocationPermission = async () => {
  if (typeof navigator !== "undefined" && navigator.geolocation && process.client) {
    const permission = await navigator.permissions.query({ name: "geolocation" });
    geoLocationValue.value = permission.state;

    // On this case we need to use onChange as there is no element for the
    // addEventListener to be attached to

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    permission.onchange = () => {
      geoLocationValue.value = permission.state;

      if (permission.state !== "granted") {
        isSearching.value = false;
      }
    };
  }
};

const getLocationFromBrowser = async () => {
  if (navigator.geolocation) {
    await nextTick(() => {
      isSearching.value = true;
      navigator.geolocation.getCurrentPosition(async (position) => {
        currentLocation.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        await nextTick();

        nearestBranches.value = await fetchNearestBranch({
          lat: currentLocation.value.lat,
          lng: currentLocation.value.lng,
          limit: 5,
          excludeBranchId: 99_990_001,
        });

        isSearching.value = false;
        updateSteps(Steps.nearest);
      });
    });
  }
};

const getLocationByPostcode = async (value: string) => {
  const geocoder = new google.maps.Geocoder();

  const options = {
    region: "GB",
    address: `${value}, UK`,
  };

  await nextTick(async () => {
    isSearching.value = true;
    await geocoder.geocode(options, async (results: any, status: any) => {
      if (status === "OK") {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        currentLocation.value = {
          lat: lat,
          lng: lng,
        };

        nearestBranches.value = await fetchNearestBranch({
          lat: currentLocation.value.lat,
          lng: currentLocation.value.lng,
          limit: 5,
          excludeBranchId: 99_990_001,
        });

        updateSteps(Steps.nearest);
        isSearching.value = false;
      }
    });
  });
};

const onSubmit = handleSubmit((values) => {
  getLocationByPostcode(values.branch);
});

onMounted(async () => {
  const loader = new Loader({
    apiKey: String(GOOGLE_MAPS_API_KEY),
  });

  loader
    .importLibrary("places")
    .then(() => {
      new google.maps.Geocoder();
    })
    .catch((error) => {
      console.error("Error occurred while loading Google Maps", error);
    });
});

watchEffect(() => {
  updateGeoLocationPermission();
});
</script>
